import React, { Component } from "react";
// import { Link } from "react-router-dom";
import niket from "./photos/niket.jpg";
import salil from "./photos/salil.jpg";
import shrey from "./photos/shrey.JPG";
import hrishikesh from "./photos/hrishikesh.jpeg";
import nishant from "./photos/nishant.jpg";
import sanket from "./photos/sanket.jpeg";
import riddhi from "./photos/riddhi.jpg";
import jaagrav from "./photos/jaagrav.jpg";
import buttonVisibility from "../buttonVisibility";
let team=[
  {
    pic:niket,
    name:"Niket Kamat Satoskar",
    tag:"Principal Tinkerer of Minimalism",
    work:"APM at Juno",
    twitter:"https://twitter.com/NiketKamat",
    instagram:"",
  },
  {
    pic:shrey,
    name:"Shrey Keny",
    tag:"Janitor in chief",
    work:"Development Manager",
    twitter:"https://twitter.com/ShreyKeny",
    instagram:"",
  },
  {
    pic:hrishikesh,
    name:"Hrishikesh Bhandari",
    tag:"Mapping Wizard",
    work:"Developer",
    twitter:"https://twitter.com/HrishikeshMB",
    instagram:"https://www.instagram.com/hrishikesh.mb/",
  },
  {
    pic:sanket,
    name:"Sanket Marathe",
    tag:"Mapping Wizard",
    work:"Developer",
    twitter:"https://twitter.com/SanketMarathe09",
    instagram:"https://www.instagram.com/sanket_marathe_09/",
  },
  {
    pic:nishant,
    name:"Nishant Tilve",
    tag:"Data Visualization Wizard",
    work:"JS Developer",
    twitter:"https://twitter.com/niche_nt",
    instagram:"",
  },
  {
    pic:riddhi,
    name:"Riddhi Siddarkar",
    tag:"Shogun of multi-tasking",
    work:"Intern",
    twitter:"https://twitter.com/siddarkar",
    instagram:"https://www.instagram.com/riddhisiddarkar/",
  },
  {
    pic:jaagrav,
    name:"Jaagrav Seal",
    tag:"JavaScript Reactor",
    work:"Intern",
    twitter:"https://twitter.com/xJaagrav",
    instagram:"https://www.instagram.com/_the.coder_/",
  },
]
class Team extends Component {
  componentDidMount() {
    window.addEventListener("scroll", buttonVisibility);
  }

  componentWillUnmount() {
    window.addEventListener("scroll", buttonVisibility);
  }


  render() {
    return (
      <div className="about">
        <h1 className="about__title">About Team</h1>
        <div className="idCard-container">
          {
            team.map((member,i)=>
              <div className="idCard" key={i}>
                <img src={member.pic} className="idCard__photo" alt="picture" width="100px" height="100px"/>
                <h3 className="idCard__name">{member.name}</h3>
                <p className="idCard__tag">{member.tag}</p>
                <p className="idCard__work">{member.work}</p>
                  <div className="sm-container">
                    {
                      member.twitter==''?<></>:
                        <a href={member.twitter} target="_blank">
                          <svg viewBox="0 0 48 48" version="1.1" className="twitter">
                            <g id="surface4074571">
                              <path d="M 48 8.601562 C 46.203125 9.398438 44.398438 10 42.398438 10.203125 C 44.398438 9 46 7 46.796875 4.796875 C 44.796875 6 42.796875 6.796875 40.601562 7.203125 C 38.601562 5.203125 36 4 33.203125 4 C 27.796875 4 23.398438 8.398438 23.398438 13.796875 C 23.398438 14.601562 23.398438 15.398438 23.601562 16 C 15.398438 15.601562 8.203125 11.796875 3.398438 5.796875 C 2.398438 7.203125 2 9 2 10.796875 C 2 14.203125 3.796875 17.203125 6.398438 19 C 4.796875 18.796875 3.203125 18.398438 2 17.796875 C 2 17.796875 2 17.796875 2 18 C 2 22.796875 5.398438 26.796875 9.796875 27.601562 C 9 27.796875 8.203125 28 7.203125 28 C 6.601562 28 6 28 5.398438 27.796875 C 6.601562 31.796875 10.203125 34.601562 14.601562 34.601562 C 11.203125 37.203125 7 38.796875 2.398438 38.796875 C 1.601562 38.796875 0.796875 38.796875 0 38.601562 C 4.398438 41.398438 9.601562 43 15 43 C 33.203125 43 43 28 43 15 C 43 14.601562 43 14.203125 43 13.796875 C 45 12.398438 46.601562 10.601562 48 8.601562 " />
                            </g>
                          </svg>
                        </a>
                    }
                    {
                      member.instagram==""?<></>:
                        <a href={member.instagram} target="_blank">
                          <svg viewBox="0 0 24 24" className="instagram">
                            {" "}
                            <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" />
                          </svg>
                        </a>
                    }
                  </div>
              </div>
            )
          }
        </div>

        <p className="thank">
          Special thanks to{" "}
          <a href="https://twitter.com/profadk" target="_blank">
            Aditya Kshirsagar
          </a>{" "}
          &{" "}
          <a href="https://twitter.com/kunksed" target="_blank">
            Raj Kunkolienkar
          </a>{" "}
          for guiding us along the way. <br /> Much thanks to so many others for
          giving us their valuable time & helping improve this project.
        </p>


      </div>
    );
  }
}

export default Team;
