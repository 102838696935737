import React, { useEffect, useState, useRef } from "react";
import { Line } from "react-chartjs-2";

import { getStateCode } from "./StateCode";

function StateDailyCharts(props) {
  const actveTabRef = useRef(),
    cnfrmTabRef = useRef(),
    rcvrdTabRef = useRef(),
    deathTabRef = useRef();

  const [dates, setDates] = useState([]);
  const [dailyActve, setDailyActve] = useState([]),
    [dailyCnfrm, setDailyCnfrm] = useState([]),
    [dailyRcvrd, setDailyRcvrd] = useState([]),
    [dailyDeath, setDailyDeath] = useState([]);

  const [lineChartData, setLineChartData] = useState({
    labels: ["10th March", "13th March", "16th March", "today"],
    datasets: [
      {
        label: "Confirmed",
        data: [47, 82, 114],
        borderColor: "#5851db",
        backgroundColor: "rgba(88, 81, 219, 0.1)",
      },
    ],
  });
  const [chartType, setChartType] = useState(0);

  useEffect(() => {
    if (props.stateName !== "none")
      fetch(
        `https://data.covid19india.org/v4/min/timeseries-${getStateCode(
          props.stateName
        )}.min.json`
      )
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          let dailyDates = [],
            da = [],
            dc = [],
            dr = [],
            dd = [];

          // for (let i = data.data.history.length - 32; i < data.data.history.length; i++) {
          //     let historyStateData = data.data.history[i].statewise, yesterdayHistoryStateData = data.data.history[i - 1].statewise;
          //     if (i === data.data.history.length - 32 || i === data.data.history.length - 22 || i === data.data.history.length - 12 || i === data.data.history.length - 1) {
          //         const date = new Date(data.data.history[i].day);
          //         dailyDates.push(date.getDate() + " " + months[date.getMonth()]);
          //     }
          //     else
          //         dailyDates.push("");
          //     for (let j = 0; j < historyStateData.length; j++) {
          //         if (historyStateData[j].state === props.stateName) {
          //             da.push(historyStateData[j].active - yesterdayHistoryStateData[j].active)
          //             dc.push(historyStateData[j].confirmed - yesterdayHistoryStateData[j].confirmed)
          //             dr.push(historyStateData[j].recovered - yesterdayHistoryStateData[j].recovered)
          //             dd.push(historyStateData[j].deaths - yesterdayHistoryStateData[j].deaths)
          //         }
          //     }
          // } Previous API Process

          let dailyDataArray = [];

          let startShowData =
              Object.keys(data[getStateCode(props.stateName)].dates).length -
              33,
            index = 0;
          for (let i in data[getStateCode(props.stateName)].dates) {
            let totalData = data[getStateCode(props.stateName)].dates[i].total;
            index++;
            if (
              totalData &&
              index > startShowData &&
              index <= startShowData + 33
            ) {
              let dateArray = new Date(`${i}`).toDateString().split(" ");
              let date =
                dateArray[1] + " " + dateArray[2] + ", " + dateArray[3];

              dailyDataArray = [
                ...dailyDataArray,
                {
                  confirmed: totalData.confirmed,
                  recovered: totalData.recovered,
                  deceased: totalData.deceased,
                  date: date,
                },
              ];
            }
          }

          for (let i = 1; i < dailyDataArray.length - 1; i++) {
            let dailyData = dailyDataArray[i],
              dailyYesterdayData = dailyDataArray[i - 1];

            dailyDates.push(
              i === dailyDataArray.length - 2 ||
                i === dailyDataArray.length - 1 - 11 ||
                i === dailyDataArray.length - 1 - 21 ||
                i === dailyDataArray.length - 1 - 31
                ? dailyData.date
                : ""
            );
            da = [
              ...da,
              dailyData.confirmed -
                (dailyData.recovered +
                  (!isNaN(dailyData.deceased) ? dailyData.deceased : 0)) -
                (dailyYesterdayData.confirmed -
                  (dailyYesterdayData.recovered +
                    (!isNaN(dailyYesterdayData.deceased)
                      ? dailyYesterdayData.deceased
                      : 0))),
            ];
            dc = [...dc, dailyData.confirmed - dailyYesterdayData.confirmed];
            dr = [...dr, dailyData.recovered - dailyYesterdayData.recovered];
            dd = [
              ...dd,
              dailyData.deceased - dailyYesterdayData.deceased
                ? dailyData.deceased - dailyYesterdayData.deceased
                : 0,
            ];
          }

          setDailyActve(da);
          setDailyCnfrm(dc);
          setDailyRcvrd(dr);
          setDailyDeath(dd);

          setDates(dailyDates);
        });
  }, [props.stateName]);

  function createLineChart(chartData, chartTheme, chartType) {
    setLineChartData({
      labels: dates,
      datasets: [
        {
          label: chartType,
          data: chartData,
          borderColor: chartTheme,
          backgroundColor: chartTheme + "1a",
        },
      ],
    });
    actveTabRef.current.style.color =
      chartTheme === "#3590ff" ? "#3590ff" : "#aaa";
    cnfrmTabRef.current.style.color =
      chartTheme === "#5851db" ? "#5851db" : "#aaa";
    rcvrdTabRef.current.style.color =
      chartTheme === "#4ccf76" ? "#4ccf76" : "#aaa";
    deathTabRef.current.style.color =
      chartTheme === "#e1306c" ? "#e1306c" : "#aaa";
  }

  useEffect(() => {
    switch (chartType) {
      case 0:
        createLineChart(dailyActve, "#3590ff", "Daily Active");
        break;
      case 1:
        createLineChart(dailyCnfrm, "#5851db", "Daily Confirmed");
        break;
      case 2:
        createLineChart(dailyRcvrd, "#4ccf76", "Daily Recovered");
        break;
      case 3:
        createLineChart(dailyDeath, "#e1306c", "Daily Deaths");
        break;
      default:
    }
  }, [chartType, dates]);
  return (
    <div className="state-graphs">
      <div className="state-graph__name">{props.stateName} - Daily Graphs</div>
      <div className="chart-container">
        <Line
          data={lineChartData}
          options={{
            scales: {
              xAxes: [
                {
                  ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                  },
                },
              ],
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      </div>
      <div className="chart-type__toggle">
        <div
          ref={actveTabRef}
          className="selected"
          onClick={() => {
            setChartType(0);
          }}
        >
          Actve
        </div>
        <div
          ref={cnfrmTabRef}
          onClick={() => {
            setChartType(1);
          }}
        >
          Cnfrm
        </div>
        <div
          ref={rcvrdTabRef}
          onClick={() => {
            setChartType(2);
          }}
        >
          Rcvrd
        </div>
        <div
          ref={deathTabRef}
          onClick={() => {
            setChartType(3);
          }}
        >
          Death
        </div>
      </div>
    </div>
  );
}

export default StateDailyCharts;
