let getStateCode = (stateName) => {
    let stateCodes = {
        "Andaman and Nicobar Islands":"AN",
        "Andhra Pradesh":"AP",
        "Arunachal Pradesh":"AR",
        "Assam":"AS",
        "Bihar":"BR",
        "Chandigarh":"CH",
        "Chattisgarh":"CT",
        "Delhi":"DL",
        "Dadra and Nagar Haveli and Daman and Diu":"DN",
        "Goa":"GA",
        "Gujarat":"GJ",
        "Himachal Pradesh":"HP",
        "Haryana":"HR",
        "Jharkhand":"JH",
        "Jammu and Kashmir":"JK",
        "Karnataka":"KA",
        "Kerala":"KL",
        "Ladakh":"LA",
        "Maharashtra":"MH",
        "Meghalaya":"ML",
        "Manipur":"MN",
        "Madhya Pradesh":"MP",
        "Mizoram":"MZ",
        "Nagaland":"NL",
        "Odisha":"OR",
        "Punjab":"PB",
        "Puducherry":"PY",
        "Rajasthan":"RJ",
        "Sikkim":"SK",
        "Telangana":"TG",
        "Tamil Nadu":"TN",
        "Tripura":"TR",
        "India":"TT",
        "":"UN",//I wonder which state could this be... Do you know?
        "Uttar Pradesh":"UP",
        "Uttarakhand":"UT",
        "West Bengal":"WB",
    };
    return stateCodes[stateName];
}

export {getStateCode};