import React, { Component } from 'react';
import RecoveredChartIndiaDaily from './RecoveredChartIndiaDaily';
//import RecoveredChartWorld from './RecoveredChartWorld';
import InfectedChartIndiaDaily from './InfectedChartIndiaDaily';
//import InfectedChartWorld from './InfectedChartWorld';
import DeathChartIndiaDaily from './DeathChartIndiaDaily';
//import DeathChartWorld from './DeathChartWorld';


let LinechartDaily = (props) => {

    function dropOptions() {
        let dropdown = document.getElementById('toggleDropdownDaily');

        if (dropdown.style.display == "block") {
            dropdown.style.display = "none"
        }

        else {
            dropdown.style.display = "block";
        }
    }

    function changeToRecoveredDaily() {
        let tag = document.getElementById('var-tag-daily');
        let label = document.getElementById('var-label-daily');
        let title = document.getElementById('var-title-daily');
        
        let recoveredChart = document.getElementById('rcvr-cht');
        let infectedChart = document.getElementById('inf-cht');
        let deathChart = document.getElementById('dth-cht');

        label.innerText = "Recovered";
        tag.style.backgroundColor = "#4ccf76";
        title.innerText = "Daily Recovered from Coronavirus";

        infectedChart.style.display = 'none';
        recoveredChart.style.display = 'block';
        deathChart.style.display = 'none';

        dropOptions();
    }

    function changeToDeathDaily() {
        let tag = document.getElementById('var-tag-daily');
        let label = document.getElementById('var-label-daily');
        let title = document.getElementById('var-title-daily');
        
        let recoveredChart = document.getElementById('rcvr-cht');
        let infectedChart = document.getElementById('inf-cht');
        let deathChart = document.getElementById('dth-cht');

        label.innerText = "Death";
        tag.style.backgroundColor = "#e1306c";
        title.innerText = "Daily Dead from Coronavirus";
        
        infectedChart.style.display = 'none';
        recoveredChart.style.display = 'none';
        deathChart.style.display = 'block';
        
        dropOptions();
    }

    function changeToInfectedDaily() {
        let tag = document.getElementById('var-tag-daily');
        let label = document.getElementById('var-label-daily');
        let title = document.getElementById('var-title-daily');
        
        let recoveredChart = document.getElementById('rcvr-cht');
        let infectedChart = document.getElementById('inf-cht');
        let deathChart = document.getElementById('dth-cht');

        label.innerText = "Confirmed";
        tag.style.backgroundColor = "#5851db";
        title.innerText = "Daily Confirmed with Coronavirus";
        
        infectedChart.style.display = 'block';
        recoveredChart.style.display = 'none';
        deathChart.style.display = 'none';
        
        dropOptions();
    }


    return (
        (props.status === "India")
        ? <div className="line-chart">

            {/* <div className="detail-bar">
                <div className="detail-bar__title-container">
                    <h2 className="detail-bar__title">Coronavirus Statistics in </h2>
                </div>
            </div> */}

            <div className="chart">
                {/* <div className="chart__message">
                    <p>Click on the points to expand details</p>
                </div> */}

                <div className="main-toggle">
                    <ul onClick={dropOptions}>
                        <li className="main-toggle__el">
                            <div className="main-toggle__tag" id="var-tag-daily"></div>
                            <p id="var-label-daily">Confirmed</p>
                        </li>
                    </ul>

                    <ul className="main-toggle__el-container" id="toggleDropdownDaily">
                        <li className="main-toggle__el" onClick={changeToInfectedDaily}>
                            <div className="main-toggle__tag" style={{ backgroundColor: "#5851db" }}></div>
                            <p>Confirmed</p> {/* <p>Infected</p> - Infected / Confirmed mean the same */}
                        </li>

                        <li className="main-toggle__el" onClick={changeToRecoveredDaily}>
                            <div className="main-toggle__tag" style={{ backgroundColor: "#4ccf76" }}></div>
                            <p>Recovered</p>
                        </li>

                        <li className="main-toggle__el" onClick={changeToDeathDaily}>
                            <div className="main-toggle__tag" style={{ backgroundColor: "#e1306c" }}></div>
                            <p>Death</p>
                        </li>
                    </ul>
                </div>

                <div className="chart__block">
                    <p className="chart__title" id="var-title-daily">Daily Confirmed with Coronavirus</p>
                    <div id="national-data-daily">
                        <RecoveredChartIndiaDaily indiaRawData={props.rawIndiaData} />
                        <InfectedChartIndiaDaily indiaRawData={props.rawIndiaData} />
                        <DeathChartIndiaDaily indiaRawData={props.rawIndiaData} />
                    </div>
                </div>
            </div>
        </div>
        : <div></div>
    )
}

export default LinechartDaily;