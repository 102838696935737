import React, { Component } from 'react'
import { useParams } from 'react-router-dom';

class CountryData extends Component {

    constructor(props) {
        super();
        this.getCountryTableData();

    }

    getCountryTableData = () => {
        fetch('https://corona.lmao.ninja/v2/countries?sort=cases')
            .then(response => {
                return response.json();
            })
            .then(data => {
                data.forEach(this.addToTable)


            })
            .catch(err => {
                console.log(err);
            })
    }

    rank = 1;

    addToTable = (element) => {

        let tab = document.getElementById('table-body-country')
        let tr = document.createElement('tr');
        //let td0 = document.createElement('td');
        let td1 = document.createElement('td');
        let td2 = document.createElement('td');
        let td3 = document.createElement('td');
        let td4 = document.createElement('td');

        //td0.innerHTML = this.rank.toString();

        if (element.country == "India") {

            td1.innerHTML = "India";
            td2.innerHTML = this.props.indiaConfirmed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            td3.innerHTML = this.props.indiaRecovered.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            td4.innerHTML = this.props.indiaDeaths.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            //tr.appendChild(td0);
            tr.appendChild(td1);
            tr.appendChild(td2);
            tr.appendChild(td3);
            tr.appendChild(td4);

            tab.appendChild(tr);


        }
        else {

            td1.innerHTML = element.country;
            td2.innerHTML = element.cases.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            td3.innerHTML = element.recovered.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            td4.innerHTML = element.deaths.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            //tr.appendChild(td0);
            tr.appendChild(td1);
            tr.appendChild(td2);
            tr.appendChild(td3);
            tr.appendChild(td4);

            tab.appendChild(tr);

        }
        this.rank += 1;
    }

    searchCountries(){
        console.log("Hello");
        let input = document.getElementById("countryInput").value;
        
        let clearBtn = document.getElementById("clearBtnCountry");

        if(input === ""){
            clearBtn.style.display = "none";
        }
        else{
            clearBtn.style.display = "block";
        }

        let filter = input.toUpperCase();
        let table = document.getElementById("countryTable");
        let tr = table.getElementsByTagName("tr");

        for(let i=0; i<tr.length; i++){
            let country = tr[i].getElementsByTagName("td")[0];
            if (country){
                let txt = country.innerText;
                if(txt.toUpperCase().indexOf(filter) !== -1){
                    tr[i].style.display = "";
                }
                else{
                    tr[i].style.display = "none";
                }
            }
        }
    }

    clearBtnPressed = () => {
        document.getElementById("countryInput").value = "";
        this.searchCountries();
        document.getElementById("clearBtnCountry").style.display = "none";
    }

    render() {
        return (
            <div id="international-country-data" className="state state-wise-data">
                <h3 className="state__title">Country Statistics</h3>
                <div className="search-container">    
                    <input type="text" id="countryInput" className="search-stats-countries" onKeyUp={this.searchCountries} placeholder="Search Country"></input>
                    <span id="clearBtnCountry" className="flex-item-span" style={{display: "none"}} onClick={this.clearBtnPressed}><button className="cleanBtn">✖️</button></span>
                </div>
                <div className="state__table-container">
                <table id="countryTable">
                    <thead>
                        <tr>
                            {/* <th>Rank</th> */}
                            <th>Country</th>
                            <th>Cnfrmd</th>
                            <th>Rcvrd</th>
                            <th>Dead</th>
                        </tr>
                    </thead>

                    <tbody id="table-body-country">

                    </tbody>
                </table>
                </div>
            </div>
        )
    }

}

export default CountryData;

