import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

class Barchart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            barData: {
                labels: ['5-10 years', '11-50', '50-100'],
                datasets: [
                    {
                        label: 'Population',
                        data: [
                            11374,
                            5000,
                            7000
                        ],
                        backgroundColor: '#5851db',
                        barPercentage: 0.4,
                        display: 'auto'

                    }
                ]
            },
        }
    }

    render() {
        return (
            <div className="chart__block" id="global-demographics">
                
                <p className="chart__title">Age demographics for World</p>

                <div className="chart__graph-container">

                    <Bar
                        data={this.state.barData}

                        options={{
                            legend: {
                                display: false
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                            },
                            responsive: true,
                            maintainAspectRatio: false
                        }

                        }

                    />
                </div>
                {/* This is a test below, please don't remove */}

                <div> {this.props.status}</div>

            </div>
        )
    }
}

export default Barchart;