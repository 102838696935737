import React, { Component } from "react";
import { useParams, Redirect } from "react-router-dom";
import briefcase from "./images/briefcase.svg";
import shareBtn from "./images/shareBtn.svg"
import NoResultsFound from './images/NoResultsFound.png'

class DisplayEventCards extends Component {
  constructor(props) {
    super();

    this.state = {
      jobDetails: null,
      redirect: null
    };
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    let shareJOB = (type, title, company, jobUrl) => {
      let shareTxt = `Check out this ${type} ${title} position at ${company} via coronatracker.in/jobs

Apply Here: ${jobUrl}`;
      let copyToClipboard = () => {
        const copyTxt = document.createElement("textarea");
        copyTxt.innerHTML = shareTxt;
        document.body.parentNode.appendChild(copyTxt);
        copyTxt.select();
        document.execCommand("copy")
        document.body.parentNode.removeChild(copyTxt);
        alert('Job Sharing data has been copied to clipboard.')
      }
      if (navigator.share) {
        navigator
          .share({
            title: "COVID19 tracker x JobSquare",
            text: shareTxt,
          })
          .then(() => {
            console.log("Thanks for sharing!");
          })
          .catch(() => {
            copyToClipboard();
          });
      } else {
        copyToClipboard();
      }
    };

    //new card generator
    let generatePromotedCards = (cards, jobData) => {
      //remove child elements before adding new ones
      if (cards !== undefined) {
        //console.log(cards.children.length);
        let chld = cards.lastElementChild;
        while (chld) {
          cards.removeChild(chld);
          chld = cards.lastElementChild;
        }
      }


      // console.log("this is jobcard", jobData);

      // let result = jobData.filter((res) => res.checked).map((ele) => ele.value);

      // console.log(result);

      if (jobData.length !== 0) {
        cards.style.display = "grid";
        document.querySelector(".load-more-btn").style.display = "block";

        for (let i = 0; i < jobData.length; i++) {
          //let row = table.insertRow();
          //event card

          let card = document.createElement("div");
          card.setAttribute("class", "eventCard");

          let div1 = document.createElement("div");
          div1.setAttribute("class", "eventCard__firstDiv");

          let image = document.createElement("img");
          image.setAttribute("class", "eventCard__image");

          let firstTextBox = document.createElement("div");
          firstTextBox.setAttribute("class", "eventCard__firstTextBox");

          let heading = document.createElement("h2");
          heading.setAttribute("class", "eventCard__title");

          let cardDetails = document.createElement("p");
          cardDetails.setAttribute("class", "eventCard__text");

          let cardDateTime = document.createElement("p");
          cardDateTime.setAttribute("class", "eventCard__text");

          let div2 = document.createElement("div");
          div2.setAttribute("class", "eventCard__secondDiv");

          let div3 = document.createElement("div");
          div3.setAttribute("class", "eventCard__thirdDiv");

          //link para
          let eventLinkPara = document.createElement("p");
          eventLinkPara.setAttribute("class", "eventCard__text link");
          //eventLinkPara.setAttribute("class", "link");

          //link
          let eventLink = document.createElement("a");
          eventLink.setAttribute("class", "eventCard__calendar");
          eventLink.setAttribute("target", "_blank");
          eventLink.textContent = "Apply now";

          //link span
          let linkSpan = document.createElement("span");

          //data-time span
          let dateTimeSpan = document.createElement("span");

          let locationBox = document.createElement("div");
          let locationText = document.createElement("div");
          locationText.setAttribute("class", "eventCard__locationText");

          let location = document.createElement("div");
          location.setAttribute("class", "eventCard__location");

          //Share Jobs Button
          let shareJobsBtn = document.createElement("div");
          // shareJobsBtn.src = shareBtn;
          shareJobsBtn.setAttribute('class', 'share__jobs-btn');
          shareJobsBtn.textContent = "Share Job";
          shareJobsBtn.onclick = () => {
            console.log('Job of ' + i + ' is clicked');
            shareJOB(jobData[i].jobType, jobData[i].title, jobData[i].companyName, jobData[i].jobUrl);
          }

          // console.log(jobData[i]);

          for (let j = 0; j < 5; j++) {
            //let cell = row.insertCell();
            // console.log(element[key]);

            if (j === 0) {
              heading.innerText = jobData[i].title;
              firstTextBox.appendChild(heading);
              // console.log(heading);
            }
            if (j === 1) {
              if (jobData[i].companyLogo == undefined) {
                image.setAttribute("src", briefcase);
                image.setAttribute("alt", "company logo");
                image.setAttribute("width", "60px");
                image.setAttribute("height", "60px");
                div1.appendChild(image);
              } else {
                image.setAttribute("src", jobData[i].companyLogo);
                image.setAttribute("width", "60px");
                image.setAttribute("height", "60px");
                image.setAttribute("alt", "company logo");
                div1.appendChild(image);
              }
            }

            if (j === 2) {
              cardDetails.innerHTML = jobData[i].companyName;
              firstTextBox.appendChild(cardDetails);
            }
            if (j === 3) {
              cardDateTime.innerText = "Type :  ";
              dateTimeSpan.innerText = jobData[i].jobType;
              cardDateTime.appendChild(dateTimeSpan);
              firstTextBox.appendChild(cardDateTime);

              div1.appendChild(firstTextBox);

              card.appendChild(div1);
            }
            if (j === 4) {
              locationText.innerText = "Location: ";
              let printData = (item, index) => {
                if (!location.textContent.includes(item.cityName))
                  location.textContent += item.cityName + ". ";
              };

              jobData[i].locations.forEach(printData);

              locationBox.appendChild(locationText);
              locationBox.appendChild(location);

              div2.appendChild(locationBox);

              eventLink.setAttribute("href", jobData[i].jobUrl);

              div3.appendChild(shareJobsBtn);

              div3.appendChild(eventLink);

              card.appendChild(div2);
              card.appendChild(div3);

              cards.appendChild(card);
            }

          }
        }
      }
      else if (nextProps.message) {
        console.log(nextProps.message)

        const noresults = document.createElement("img");
        noresults.className = "noresults";
        noresults.src = NoResultsFound;//The No Results Found illustration
        document.querySelector(".load-more-btn").style.display = "none";

        try {
          cards.style.display = "flex";
          cards.appendChild(noresults);//To prevent undefined cards errors when page loads
        }
        catch (err) { }
      }

      //   for (let element of data) {
      //     //let row = table.insertRow();
      //     //event card
      //     let card = document.createElement("div");
      //     card.setAttribute("class", "eventCard");

      //     let heading = document.createElement("h2");
      //     heading.setAttribute("class", "eventCard__title");

      //     let cardDetails = document.createElement("p");
      //     cardDetails.setAttribute("class", "eventCard__text");

      //     let cardDateTime = document.createElement("p");
      //     cardDateTime.setAttribute("class", "eventCard__text");

      //     //link para
      //     let eventLinkPara = document.createElement("p");
      //     eventLinkPara.setAttribute("class", "eventCard__text link");
      //     //eventLinkPara.setAttribute("class", "link");

      //     //link
      //     let eventLink = document.createElement("a");
      //     eventLink.setAttribute("class", "eventCard__calendar");
      //     eventLink.setAttribute("target", "_blank");

      //     //link span
      //     let linkSpan = document.createElement("span");

      //     //data-time span
      //     let dateTimeSpan = document.createElement("span");

      //     let i = 0;
      //     for (let key in element) {
      //       //let cell = row.insertCell();
      //       //console.log(element[key]);

      //       if (i === 0) {
      //         heading.innerText = element[key];
      //         card.appendChild(heading);
      //       }
      //       if (i === 1) {
      //         cardDetails.innerHTML = element[key];
      //         card.appendChild(cardDetails);
      //       }
      //       if (i === 2) {
      //         cardDateTime.innerText = "Date & Time: ";
      //         dateTimeSpan.innerText = element[key];
      //         cardDateTime.appendChild(dateTimeSpan);
      //         card.appendChild(cardDateTime);
      //       }
      //       if (i === 3) {
      //         eventLink.setAttribute("href", element[key]);
      //         linkSpan.innerText = element[key];
      //         eventLink.appendChild(linkSpan);
      //         eventLinkPara.innerText = "Link: ";
      //         eventLinkPara.appendChild(eventLink);
      //         card.appendChild(eventLinkPara);
      //         cards.appendChild(card);
      //       }
      //       i += 1;
      //     }
      //   }
    };

    let jobs = nextProps.jobDetails;

    let cardsDiv = document.getElementsByClassName("events-container")[0];



    if (jobs !== null && jobs !== undefined) {
      //age.statewise.forEach(addToTable);
      generatePromotedCards(cardsDiv, jobs);
      //console.log(events);
      return { eventsData: jobs }; //sets new state
    } else {
      return null;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <div className="events-container">
        {/* <div className="eventCard">
              <h2 className="eventCard__title">Virtual Youth Summit 2020</h2>
              <p className="eventCard__text">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis est incidunt tenetur iure facilis, laboriosam optio totam numquam voluptatum dolorem illo, ducimus, temporibus enim maxime unde doloribus! Harum, nam et!</p>
              <p className="eventCard__text">Date &amp; Time: <span>8AM IST, April 24</span></p>
              <p className="eventCard__text link">Link: <a href="" target="blank"><span>https://www.eventbrite.com/e/virtual-youth-summit-2020-registration-101645594710?aff=ebdssbonlinesearch</span></a></p>
            </div>  */}
      </div>
    );
  }
}

export default DisplayEventCards;
