import React, { Component } from 'react'

class MapMyIndia extends Component {


    render() {
        return (
            <div> 
                <div>
                    <div style={{fontSize : "15px", textAlign : "left", paddingBottom : "15px", color : "#aaaaaa"}}> Add your location to find Vaccine Centres, Hospitals, Testing Centres, Isolation Centres etc in your area </div> 
                    <iframe width="100%" height="500px" src="https://maps.mapmyindia.com/covid-places/embed" allow="geolocation" >
                    </iframe>
                </div>
                <div style={{fontSize : "15px", textAlign : "center", paddingTop : "10px", color : "#aaaaaa"}}> Powered by MapMyIndia </div> 
            </div>
        )
    }
}
    
export default MapMyIndia;