import React, { Component } from "react";

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading_button: "",
    };
  }

  myLocation = () => {
    let latitude = 0;
    let longitude = 0;
    this.setState({
      loading_button: true,
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
      document.getElementById("location-message").innerHTML =
        "Geolocation is not supported in your browser";
    }

    function showPosition(position) {
      latitude = (position.coords.latitude / 2) * 3.5 + 2.675;
      longitude = (position.coords.longitude / 2) * 3.5 + 2.675;

      let check =
        "https://script.google.com/macros/s/AKfycbwqcrVhD9D6Oi2aIi9EG16ks3hLjbJqag_jznwxqpY88xdoBQun/exec?";

      let url = check + "lat=" + latitude + "&" + "long=" + longitude;

      fetch(url)
        .then((response) => {
          return response.text();
        })
        .then((data) => {
          console.log(data);
          document.getElementById("location-message").innerHTML =
            "<p class='location__text'>You're approximately " +
            parseFloat(data).toFixed(2) +
            " KM away from nearest confirmed case. <span>Don't panic, there might be +/- 5km error in some cases. Stay Home and Stay Safe.</span></p>";
          if (parseFloat(data).toFixed(2) < 100) {
            document.getElementById("location-message").style.color =
              "rgb(225, 48, 108)";
          } else {
            document.getElementById("location-message").style.color = "#25D366";
          }

          console.log("Real:", position.coords.latitude, " false:", latitude);
          console.log("Real:", position.coords.longitude, " false:", longitude);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setTimeout(() => {
      this.setState({
        loading_button: false,
      });
    }, 3000);
  };

  render() {
    return (
      <div className="location">
        <div className="location__title">
          How far are you from nearest COVID19 case in India?
        </div>
        <div id="location-message" className="location__message">
          Your current location will be used to calculate the distance from the
          nearest positive case.
        </div>
        {/* <div
          className={`button-location ${
            this.state.loading_button ? "loading" : ""
          }`}
          onClick={this.myLocation}
        >
          Find distance
        </div> */}

        <div className="button-location disabled">Temporarily Disabled</div>
      </div>
    );
  }
}

export default Location;
