//scroll to top button

const buttonVisibility = () => {
    if(document.getElementById("goTopButton")){
      //console.log("hello");
      let goTop = document.getElementById("goTopButton");
      //let element = e.target;

      //console.log(document.documentElement.scrollHeight);
      if(document.body.scrollTop > 100 || document.documentElement.scrollTop > 100){
        goTop.style.display = "block";
      }
      else{
        goTop.style.display = "none";
      }
    }
  }

  export default buttonVisibility;