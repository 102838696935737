//import React from 'react';
import React, {Component} from "react";
import buttonVisibility from '../buttonVisibility';

class Prevention extends Component{

    componentDidMount(){
        window.addEventListener('scroll', buttonVisibility);

        var xmlhttp = new XMLHttpRequest();
        xmlhttp.open('GET', 'https://spreadsheets.google.com/feeds/cells/1IzMJXirERDz2LcU6Jm7TEQRC-FfIJfDeoQxk8uBuqYI/od6/public/full?alt=json', true);
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState == 4) {
                if (xmlhttp.status == 200) {
                    var obj = JSON.parse(xmlhttp.responseText);
                    var data = obj.feed.entry;
                    var n = data.length / 4
                    console.log(data);
                }
            }

            let arr = [];
            let j = 0

            for (let i = 0; i < n; i++) {
                j++;
                arr[i] = `
                <div class="tips__block">
                    <h2 class="tips__title">${data[j++].content.$t}</h2>
                    <p class="tips__text">${data[j++].content.$t}</p>
                </div>
                `
                j++;
            }

            document.getElementById('tips_container').innerHTML = arr.join('');

        };
        xmlhttp.send(null);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', buttonVisibility);
    }


    render(){
        return (
            <div>
                <h1 className="preventive__heading"><span>World Health Organization Guidelines </span>for Coronavirus</h1>
                <div className="tips" id="tips_container">
                    {/* Data will come from the google sheets */}
                </div>

                {/* Twitter feed and youtube videos will come here */}

                {/* <blockquote class="twitter-tweet"><p lang="es" dir="ltr">Mdres con síntomas de <a href="https://twitter.com/hashtag/COVID19?src=hash&amp;ref_src=twsrc%5Etfw">#COVID19</a> , amamantando o contacto piel-piel deben🔹Higiene respiratoria, (cubre bocas) incluida durante la alimentación 🔹Higiene de manos antes y después del contacto con un niño 🔹limpiar y desinfectar superficies que han estado en contacto   “OMS”</p>&mdash; Alvaro Peña Jimenez (@drpejim) <a href="https://twitter.com/drpejim/status/1240274846065528832?ref_src=twsrc%5Etfw">March 18, 2020</a></blockquote>
                <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}
            </div>
        )
    }
}

export default Prevention; 