import React, { useEffect, useState, useRef } from "react";
import { Line } from "react-chartjs-2";

import { getStateCode } from "./StateCode";

function StateCumCharts(props) {
  const actveTabRef = useRef(),
    cnfrmTabRef = useRef(),
    rcvrdTabRef = useRef(),
    deathTabRef = useRef();

  const [dates, setDates] = useState([]);
  const [cumActve, setCumActve] = useState([]),
    [cumCnfrm, setCumCnfrm] = useState([]),
    [cumRcvrd, setCumRcvrd] = useState([]),
    [cumDeath, setCumDeath] = useState([]);

  const [lineChartData, setLineChartData] = useState({
    labels: ["10th March", "13th March", "16th March", "today"],
    datasets: [
      {
        label: "Confirmed",
        data: [47, 82, 114],
        borderColor: "#5851db",
        backgroundColor: "rgba(88, 81, 219, 0.1)",
      },
    ],
  });
  const [chartType, setChartType] = useState(0);

  useEffect(() => {
    if (props.stateName !== "none")
      fetch(
        `https://data.covid19india.org/v4/min/timeseries-${getStateCode(
          props.stateName
        )}.min.json`
      )
        .then((e) => {
          return e.json();
        })
        .then((data) => {
          let dailyDates = [],
            ca = [],
            cc = [],
            cr = [],
            cd = [];
          // for (let i = data.data.history.length - 32; i < data.data.history.length; i++) {
          //     let historyStateData = data.data.history[i].statewise;
          //     if (i === data.data.history.length - 32 || i === data.data.history.length - 22 || i === data.data.history.length - 12 || i === data.data.history.length - 1) {
          //         const date = new Date(data.data.history[i].day);
          //         dailyDates.push(date.getDate() + " " + months[date.getMonth()]);
          //     }
          //     else
          //         dailyDates.push("");
          //     for (let j = 0; j < historyStateData.length; j++) {
          //         if (historyStateData[j].state === props.stateName) {
          //             ca.push(historyStateData[j].active)
          //             cc.push(historyStateData[j].confirmed)
          //             cr.push(historyStateData[j].recovered)
          //             cd.push(historyStateData[j].deaths)
          //         }
          //     }
          // } Previous API Process

          let startShowData =
              Object.keys(data[getStateCode(props.stateName)].dates).length -
              32,
            index = 0;
          for (let i in data[getStateCode(props.stateName)].dates) {
            let totalData = data[getStateCode(props.stateName)].dates[i].total;
            index++;

            if (
              totalData &&
              index > startShowData &&
              index < startShowData + 32
            ) {
              let dateArray = new Date(`${i}`).toDateString().split(" ");
              let date =
                dateArray[1] + " " + dateArray[2] + ", " + dateArray[3];
              dailyDates.push(
                index === startShowData + 31 ||
                  index === startShowData + 31 - 10 ||
                  index === startShowData + 31 - 20 ||
                  index === startShowData + 1
                  ? date
                  : ""
              );

              ca = [
                ...ca,
                totalData.confirmed -
                  (totalData.recovered +
                    (!isNaN(totalData.deceased) ? totalData.deceased : 0)),
              ];
              cc = [...cc, totalData.confirmed];
              cr = [...cr, totalData.recovered];
              cd = [...cd, totalData.deceased ? totalData.deceased : 0];
            }
          }

          setCumActve(ca);
          setCumCnfrm(cc);
          setCumRcvrd(cr);
          setCumDeath(cd);

          setDates(dailyDates);
        });
  }, [props.stateName]);

  function createLineChart(chartData, chartTheme, chartType) {
    setLineChartData({
      labels: dates,
      datasets: [
        {
          label: chartType,
          data: chartData,
          borderColor: chartTheme,
          backgroundColor: chartTheme + "1a",
        },
      ],
    });
    actveTabRef.current.style.color =
      chartTheme === "#3590ff" ? "#3590ff" : "#aaa";
    cnfrmTabRef.current.style.color =
      chartTheme === "#5851db" ? "#5851db" : "#aaa";
    rcvrdTabRef.current.style.color =
      chartTheme === "#4ccf76" ? "#4ccf76" : "#aaa";
    deathTabRef.current.style.color =
      chartTheme === "#e1306c" ? "#e1306c" : "#aaa";
  }

  useEffect(() => {
    switch (chartType) {
      case 0:
        createLineChart(cumActve, "#3590ff", "Total Active");
        break;
      case 1:
        createLineChart(cumCnfrm, "#5851db", "Total Confirmed");
        break;
      case 2:
        createLineChart(cumRcvrd, "#4ccf76", "Total Recovered");
        break;
      case 3:
        createLineChart(cumDeath, "#e1306c", "Total Deaths");
        break;
      default:
    }
  }, [chartType, dates]);
  return (
    <div className="state-graphs">
      <div className="state-graph__name">
        {props.stateName} - Cumulative Graphs
      </div>
      <div className="chart-container">
        <Line
          data={lineChartData}
          options={{
            scales: {
              xAxes: [
                {
                  ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                  },
                },
              ],
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      </div>
      <div className="chart-type__toggle">
        <div
          ref={actveTabRef}
          className="selected"
          onClick={() => {
            setChartType(0);
          }}
        >
          Actve
        </div>
        <div
          ref={cnfrmTabRef}
          onClick={() => {
            setChartType(1);
          }}
        >
          Cnfrm
        </div>
        <div
          ref={rcvrdTabRef}
          onClick={() => {
            setChartType(2);
          }}
        >
          Rcvrd
        </div>
        <div
          ref={deathTabRef}
          onClick={() => {
            setChartType(3);
          }}
        >
          Death
        </div>
      </div>
    </div>
  );
}

export default StateCumCharts;
