//import React from 'react';
import React, { Component } from 'react';
import buttonVisibility from '../buttonVisibility';
class Donate extends Component {

    componentDidMount(){
        window.addEventListener('scroll', buttonVisibility);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', buttonVisibility);
    }


    render(){
        return (
            <div className="donate">
                <h1 className="donate__heading">Support us in helping India fight COVID19</h1>
                <div className="donate__info">
                    <h2 className="donate__info__title">Where will my money go once I donate?</h2>
                    {/* <p className="donate__info__text">Each and every Rupee will be used to help the people in need. May it be in the form of giving them food, health kits or other essential needs. A small percentage will be used in building out more features in this platform to help people better and share awareness. We are a group of dedicated students and youngsters who are building this completely non-profit platform with the sole aim of helping people and none of the donations will be used for ourselves.</p> */}
                    <p className="donate__info__text">
                        The money donated will be used to help people in need, may it be in the form of providing them meals, protective equipment or other essential needs.
                        A small percentage of these donations will be used in building out more features on this platform to help people better.
                        We are a group of dedicated youngsters and students, building this platform completely as non-profit project with the sole aim of helping people by providing them verified accurate information. None of the donations will be used for ourselves.
                        <br /> #StayHomeForIndia #StaySafe
                        </p>

                    <h2 className="donate__info__title">Donate using UPI, Net Banking or Credit/Debit cards via InstaMojo</h2>
                    {/* <a href="" className="button">Donate funds</a>       */}

                    <a href="https://www.instamojo.com/coronatracker/donation-towards-wwwcoronatrackerin/" rel="im-checkout" data-text="Donate" className="button" data-layout="vertical">Donate</a>
                    <script src="https://js.instamojo.com/v1/button.js"></script>

                </div>

                <div className="donate__info">
                    <h2 className="donate__info__title">All the donations and the expenses will be made public soon</h2>
                    {/* <p className="donate__info__text">We would be putting your names (along with the donation amout if you want) after you donate for everyone to see and get motivated and also a ledger of where the expenses have been will be maintained for public access as well</p> */}
                    {/* <a href="" className="button">Check who has donated funds</a> */}
                </div>

            </div>

        )
    }
}

export default Donate; 