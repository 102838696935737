import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

class DeathChartIndiaDaily extends Component {

    constructor(props) {
        super(props);
        //this.value();
    }

    state = {
        chartDataIndia: {
            // All dates should come down. Take weekly periods. DYNAMIC
            labels: ['10th March', '13th March', '16th March', 'today'],
            datasets: [
                {
                    label: 'Death',
                    data: [
                        0, 2, 2

                    ],
                    borderColor: '#e1306c',
                    backgroundColor: 'rgba(225, 48, 108, 0.1)'
                }
            ]
        }
    }

    
    //NEW CODE USING DATA FROM PROPS

    static getDerivedStateFromProps(nextProps, prevState) {

        let res = nextProps.indiaRawData;

        if (res != undefined || res != null) {
            // console.log(res.statewise[0]);

            let dataDeaths = []; //Y axis data
            let labels = [];  //labels for the X axis
            let counter = 0; //counter for counting days iterated through
            let days = 0; //number of days

            let offset = 0; //Used to mark the start of the 30 datapoints to plot. Updated later in code

            let pointsPlotted = 0; //counter to keep track of points plotted

            counter = 1;
            pointsPlotted = 0;

            days = res.cases_time_series.length; //total number of days given by the API 

            offset = days - 30; //basically show only 30 datapoints at a time on the graph
            //offset = Math.round((days - 32)/(10)); //offset claculated here
            //let daysTrimmed = (days - offset); //days for which data is to be plotted
            let daysTrimmed = 30; //30 days
            // let midLabel = Math.round(daysTrimmed/2);
            // let midLabelLow = Math.round(midLabel / 2);
            // let midLabelHigh = Math.round((midLabel + daysTrimmed) / 2);

            //These are the 2 labels which will be shown on X axis 
            let midLabelLow = Math.round(daysTrimmed * (1 / 3));
            let midLabelHigh = Math.round(daysTrimmed * (2 / 3));

            // console.log('midlabel', midLabel);
            // console.log('midlabellow', midLabelLow);
            // console.log('midlabelhigh', midLabelHigh);

            //console.log('offset: ', offset);
            // console.log('plotted', pointsPlotted);
            var tmp = 0; //keeps track of the number of points plotted
            res.cases_time_series.map(item => {
                //tmp = 0;
                if (counter < offset) {
                    tmp = 0;
                }
                if ((counter >= offset) || (counter === days)) {
                    //console.log('tmpVal: ', tmp);
                    if (item.dailydeceased === '') {
                        //if today's cases are given as null (if they didn't update today's cases) then show previous day data instead of today
                        if (counter === days) {
                            labels[labels.length - 1] = res.cases_time_series[days - 2].date;
                            return; //like a break from the loop
                        }
                        else {
                            dataDeaths.push(res.cases_time_series[counter - 2].dailydeceased);
                        }
                    }
                    else {
                        dataDeaths.push(item.dailydeceased);
                    }


                    if ((counter === offset) || ((tmp === midLabelLow) || (tmp === midLabelHigh))) {
                        labels.push(item.date);
                    }
                    else if (counter === days) {
                        labels.push(item.date);
                        //console.log('reached today');
                    }
                    else {
                        labels.push('');
                    }
                    pointsPlotted += 1;

                }
                counter += 1;
                tmp += 1;
            });

            // console.log('labels', labels);
            // console.log('infected', dataDeaths);

            //THIS IS THE this.setState() equivalent
            return({
                chartDataIndia: {
                    // All dates should come down. Take weekly periods. DYNAMIC
                    labels: labels,
                    datasets: [
                        {
                            label: 'Death',
                            data: dataDeaths,
                            lineTension: 0,
                            borderColor: '#e1306c',
                            backgroundColor: 'rgba(225, 48, 108, 0.1)'
                        }
                    ]
                },
            });
        }
        else{
            return null;
        }

    }


    render() {
        return (
            <div className="chart__graph-container death-chart-daily" id="dth-cht">
                <Line
                    data={this.state.chartDataIndia}
                    options={{
                        scales: {
                            xAxes: [{
                                ticks: {
                                    autoSkip: false,
                                    maxRotation: 0,
                                    minRotation: 0
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                    stepSize: 100,
                                }
                            }]
                        },
                        responsive: true,
                        maintainAspectRatio: false
                    }}
                />
            </div>
        )
    }
}

export default DeathChartIndiaDaily;