import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

class BarchartIndia extends Component {

    constructor(props) {
        super(props);

        this.indiaAge();
    }

    state = {

        barDataIndia: {
            labels: ['0-10', '10-20 ', '20-30 ', '30-40 ', '40-50 ', '50-60 ', '60-70 ', '70-80 ', '80-100'],
            datasets: [
                {
                    label: 'No. of cases',
                    data: [
                        

                    ],
                    backgroundColor: '#5851db',
                    barPercentage: 0.4,
                    display: 'auto'

                }
            ]
        }

    }

    indiaAge = () => {

        let zeroToTen = 0;
        let TenToTwenty = 0;
        let TwentyToThirty = 0;
        let ThirtyToForty = 0;
        let FortyToFifty = 0;
        let FiftyToSixty = 0;
        let SixtyToSeventy = 0;
        let SeventyToEighty = 0;
        let EightyToHundread = 0;

        let sortAgeData = (element) => {

            if (element["agebracket"] > 0 && element["agebracket"] < 10) {
                zeroToTen = zeroToTen + 1;
                // console.log(zeroToTen)
            }
            if (element["agebracket"] >= 10 && element["agebracket"] < 20) {
                TenToTwenty = TenToTwenty + 1;
                // console.log(TenToTwenty)
            }
            if (element["agebracket"] >= 20 && element["agebracket"] < 30) {
                TwentyToThirty = TwentyToThirty + 1;
                // console.log(TwentyToThirty)
            }
            if (element["agebracket"] >= 30 && element["agebracket"] < 40) {
                ThirtyToForty = ThirtyToForty + 1;
                // console.log(ThirtyToForty)
            }
            if (element["agebracket"] >= 40 && element["agebracket"] < 50) {
                FortyToFifty = FortyToFifty + 1;
                // console.log(FortyToFifty)
            }
            if (element["agebracket"] >= 50 && element["agebracket"] < 60) {
                FiftyToSixty = FiftyToSixty + 1;
                // console.log(FiftyToSixty)
            }
            if (element["agebracket"] >= 60 && element["agebracket"] < 70) {
                SixtyToSeventy = SixtyToSeventy + 1;
                // console.log(SixtyToSeventy)
            }
            if (element["agebracket"] >= 70 && element["agebracket"] < 80) {
                SeventyToEighty = SeventyToEighty + 1;
                // console.log(SeventyToEighty)
            }
            if (element["agebracket"] >= 80 && element["agebracket"] < 100) {
                EightyToHundread = EightyToHundread + 1;
                // console.log(EightyToHundread)
            }

        }

        let letTheChartRise = () => {
            //console.log('dsdsdsds')
            this.setState({
                barDataIndia: {
                    labels: ['0-10', '10-20 ', '20-30 ', '30-40 ', '40-50 ', '50-60 ', '60-70 ', '70-80 ', '80-100'],
                    datasets: [
                        {
                            label: 'No. of cases',
                            data: [
                                zeroToTen,
                                TenToTwenty,
                                TwentyToThirty,
                                ThirtyToForty,
                                FortyToFifty,
                                FiftyToSixty,
                                SixtyToSeventy,
                                SeventyToEighty,
                                EightyToHundread

                            ],
                            backgroundColor: '#5851db',
                            barPercentage: 0.4,
                            display: 'auto'

                        }
                    ]
                }
            })
        }


        fetch('https://data.covid19india.org/raw_data.json')
            .then(response => {
                return response.json();
            })
            .then(data => {
                //console.log(data.raw_data)
                data.raw_data.forEach(sortAgeData);

            })
            .then(
                letTheChartRise
            )
            .catch(err => {
                console.log('Error in Age Demographic for India')
            })





    }


    // indiaAge = () => {
    //     fetch('https://script.googleusercontent.com/macros/echo?user_content_key=O7moaUssZSVL3VgdQcvR7pcw9hu_XUO0lHmfwA2O3RCy5KosGAGZNVCeIqZpJRUheOtLgZw2rDy5jpqokLHVg5stCI2N0Su1m5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnAXzbfG3JM6_-NH_XWfIoAuf_qYOu4qn1TCa7dY0VSWVd7U0BOQdi8u-xDTCK_2aReIqWsDnSrEd&lib=M-zCWD_cwW2oPqzXseJ8bZbhb8GuvlFSd')
    //         .then(response => {
    //             return response.json()
    //         })
    //         .then(data => {
    //             // console.log(data.Demography[0])

    //             this.setState({
    //                 barDataIndia: {
    //                     labels: ['0-10', '10-20 ', '20-30 ', '30-40 ', '40-50 ', '50-60 ', '60-70 ', '70-80 ', '80-100'],
    //                     datasets: [
    //                         {
    //                             label: 'No. of cases',
    //                             data: [
    //                                 data.Demography[0]["0-10"], 
    //                                 data.Demography[0]["10-20"],
    //                                 data.Demography[0]["20-30"],
    //                                 data.Demography[0]["30-40"],
    //                                 data.Demography[0]["40-50"],
    //                                 data.Demography[0]["50-60"],
    //                                 data.Demography[0]["60-70"],
    //                                 data.Demography[0]["70-80"],
    //                                 data.Demography[0]["80-90"] + data.Demography[0]["90-100"]

    //                             ],
    //                             backgroundColor: '#5851db',
    //                             barPercentage: 0.4,
    //                             display: 'auto'

    //                         }
    //                     ]
    //                 }
    //             })
    //         })
    //         .catch(err => {
    //             console.log(err)
    //         })
    // }





    render() {
        return (
            <div className="chart__block" id="national-demographics">
                {}
                <p className="chart__title">Age demography for confirmed cases in India *</p>

                <div className="chart__graph-container">

                    <Bar
                        data={this.state.barDataIndia}

                        options={{
                            legend: {
                                display: false
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                            },
                            responsive: true,
                            maintainAspectRatio: false
                        }

                        }

                    />
                </div>
                {/* This is a test below, please don't remove */}

                {/* <span> *  </span> */}

            </div>
        )
    }
}

export default BarchartIndia;