import ReactGA from "react-ga";

export const initGA = (trackingID, options) => {           
    ReactGA.initialize(trackingID, options); 
 }

export const Event = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };