import React, { Component } from "react";
import Mapsection from "./Mapsection";
import Linechart from "./LineChart";
import LinechartDaily from "./LineChartDaily";
// import Barchart from './BarChart';
import BarChartIndia from "./BarChartIndia";
import BarChartWorld from "./BarChartWorld";
import india from "./india.png";
import world from "./globe.png";

import Location from "./Location";
import StateData from "./StateData";
import CountryData from "./CountryData";
import { Link } from "react-router-dom";

import buttonVisibility from "../buttonVisibility";
//import StatePage from "./StatePage";

class Home extends Component {
  constructor(props) {
    super(props);

    //Removed from inside Render method and added here so it's loaded first and API data called
    this.jhuCasesAPI();
    this.IndiaCasesAPI();
    this.worldTimelineData();
    this.districtData();
  }

  state = {
    value: 0,
    country: "India",
    confirmedIndia: 1,
    recoveredIndia: 2,
    deathsIndia: 3,
    confirmedWorld: 4,
    recoveredWorld: 5,
    deathsWorld: 6,
    lastUpdate: "",
    indiaRawData: null, // Indian Data for graphs
    indiaRawDataDaily: null,
    worldTimelineData: null, // World data for graphs
    districtData: null,
    stateDailyDelta: null,
    whatsappText: "",
    twitterText: "",
  };

  componentDidMount() {
    window.addEventListener("scroll", buttonVisibility);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", buttonVisibility);
  }

  dropOptions() {
    let dropdown = document.getElementById("dataDropdown");

    if (dropdown.style.display == "block") {
      dropdown.style.display = "none";
    } else {
      dropdown.style.display = "block";
    }
  }

  changeToIndia() {
    let tag = document.getElementById("data-var-tag");
    let label = document.getElementById("data-var-label");
    let indiaTag = document.getElementById("indiaTag");
    let worldTag = document.getElementById("worldTag");
    label.innerText = "IN";
    // tag.style.backgroundColor = "#FF9933";
    tag.style.display = "none";
    worldTag.style.display = "none";
    indiaTag.style.display = "block";

    document.getElementById("national-data").style.display = "block";
    document.getElementById("global-data").style.display = "none";
    //document.getElementById('national-demographics').style.display = 'block';
    // document.getElementById('global-demographics').style.display = 'none';
    document.getElementById("national-state-data").style.display = "block";
    document.getElementById("international-country-data").style.display =
      "none";
    document.getElementById("india-last-update").style.display = "block";

    // this.IndiaCasesAPI()
    this.setState({ country: "India" });
    this.dropOptions();
  }

  changeToWorld() {
    let tag = document.getElementById("data-var-tag");
    let label = document.getElementById("data-var-label");
    let indiaTag = document.getElementById("indiaTag");
    let worldTag = document.getElementById("worldTag");
    label.innerText = "All";
    tag.style.display = "none";
    indiaTag.style.display = "none";
    worldTag.style.display = "block";
    tag.style.backgroundColor = "#7AD7F0";
    this.setState({ country: "World" });

    document.getElementById("national-data").style.display = "none";
    document.getElementById("global-data").style.display = "block";
    //document.getElementById('national-demographics').style.display = 'none';
    // document.getElementById('global-demographics').style.display = 'block';
    document.getElementById("national-state-data").style.display = "none";
    document.getElementById("international-country-data").style.display =
      "block";
    document.getElementById("india-last-update").style.display = "none";

    // this.jhuCasesAPI();
    this.dropOptions();
  }

  IndiaCasesAPI = () => {
    // console.log('India ko bulaya ');

    fetch("https://data.covid19india.org/data.json")
      .then((response) => {
        return response.json();
      })
      .then((info) => {
        // console.log(info);
        if (this.state.confirmedIndia === 1) {
          //if(this.state.whatsappText === ''){
          let i;
          let leastAffected = "%0A%0ATop 5 least affected Indian states:%0A",
            mostAffected =
              "🚨 *COVID-19 updates for India* 🇮🇳 %0A%0A *_States most affected_*:  🦠 😷%0A%0A";
          for (i = 1; i < 6; i++) {
            mostAffected += `${i}) *${info.statewise[i].state}*:%0A Active: ${info.statewise[i].active}%0A Recovered: ${info.statewise[i].recovered}%0A Deaths: ${info.statewise[i].deaths}%0A Total Cases: ${info.statewise[i].confirmed}%0A%0A`;
          }

          let twitterText =
            "https://twitter.com/intent/tweet?text=" +
            `🚨 %40COVID19_INDIA Update for 🇮🇳 on ${
              info.statewise[0].lastupdatedtime.split(" ")[0]
            }%0A%0ATotal Active cases: ${
              info.statewise[0].active
            }%0ATotal Recovered cases: ${
              info.statewise[0].recovered
            }%0ATotal Deaths: ${
              info.statewise[0].deaths
            }%0ATotal Confirmed cases: ${
              info.statewise[0].confirmed
            }%0A%0A %23COVID19 %23IndiaFightsCorona 🦠%0A%0AShared via coronatracker.in 📊 📈`;
          //least affected states
          // let keyLength = Object.keys(info.statewise).length - 1;
          // i = keyLength;

          // while(i > keyLength-5){
          //     let srNo = i -keyLength + 1;
          //     leastAffected += `${srNo}.*${info.statewise[i].state}*:%0A *Confirmed*: ${info.statewise[i].confirmed},  *Recovered*: ${info.statewise[i].recovered},  *Deaths*: ${info.statewise[i].deaths}%0A`;
          //     i -= 1;
          // }

          let waText =
            "https://api.whatsapp.com/send?text=" +
            mostAffected +
            "Shared via *coronatracker.in*  📊 🇮🇳";

          //}
          this.setState({
            confirmedIndia: info.statewise[0].confirmed,
            recoveredIndia: info.statewise[0].recovered,
            deathsIndia: info.statewise[0].deaths,
            lastUpdate: info.statewise[0].lastupdatedtime,
            indiaRawData: info,
            indiaRawDataDaily: info,
            stateDailyDelta: info.statewise,
            whatsappText: waText,
            twitterText: twitterText,
          });
        }
        //console.log(this.state);
        // console.log(this.state.confirmedIndia);
        // console.log( this.state.recoveredIndia);
        // console.log( this.state.deathsIndia);
        // console.log(this.state.indiaRawData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  jhuCasesAPI = () => {
    // console.log('world ko invoke kela');

    fetch("https://corona.lmao.ninja/v2/all")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log('JHU called.');
        if (this.state.confirmedWorld == 4) {
          this.setState({
            confirmedWorld: data.cases,
            recoveredWorld: data.recovered,
            deathsWorld: data.deaths,
          });
        }

        // console.log(this.state.confirmedWorld);
        // console.log( this.state.recoveredWorld);
        // console.log( this.state.deathsWorld);
      })
      .catch((err) => {
        console.log("Kuch toh error hai");
      });
  };

  worldTimelineData = () => {
    fetch("https://corona.lmao.ninja/v2/historical")
      .then((response) => {
        return response.json();
      })
      .then((info) => {
        // console.log(info);

        if (this.state.worldTimelineData == null) {
          this.setState({
            worldTimelineData: info,
          });
        }
      });
  };

  districtData = () => {
    if (this.state.districtData === null) {
      fetch("https://data.covid19india.org/state_district_wise.json")
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          //console.log(data);
          let distDt = {};
          for (let stateKey in data) {
            let tmp = {};
            for (let dist in data[stateKey]["districtData"]) {
              tmp[dist] = data[stateKey]["districtData"][dist];
            }
            distDt[stateKey] = tmp;
          }
          //console.log(distDt);

          this.setState({
            districtData: distDt,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log(this.state.districtData);
    }
  };

  render() {
    return (
      <div className="home-content">
        <div className="detail-bar">
          <div className="detail-bar__title-container">
            <h2 className="detail-bar__title">
              Coronavirus Location Tracker for{" "}
            </h2>
            {/* select will come here */}
            <div className="main-toggle">
              <ul onClick={this.dropOptions}>
                <li className="main-toggle__el">
                  <div
                    className="main-toggle__tag"
                    id="data-var-tag"
                    style={{ display: "none" }}
                  ></div>
                  <img
                    className="main-toggle__tag-img"
                    width="24px"
                    height="24px"
                    alt="india"
                    id="indiaTag"
                    style={{ display: "block" }}
                    src={india}
                  />
                  <img
                    className="main-toggle__tag-img"
                    alt="world"
                    width="24px"
                    height="24px"
                    id="worldTag"
                    style={{ display: "none" }}
                    src={world}
                  />
                  <p id="data-var-label">IN</p>
                </li>
              </ul>

              <ul className="main-toggle__el-container" id="dataDropdown">
                <li
                  className="main-toggle__el"
                  onClick={() => this.changeToIndia()}
                >
                  {/* <div className="main-toggle__tag india-tag"><img src={india} /></div> */}
                  <img
                    className="main-toggle__tag-img"
                    alt="india"
                    width="24px"
                    height="24px"
                    style={{ display: "block" }}
                    src={india}
                  />
                  <p>IN</p>
                </li>

                <li
                  className="main-toggle__el"
                  onClick={() => this.changeToWorld()}
                >
                  {/* <div className="main-toggle__tag" style={{ backgroundColor: "#7AD7F0" }}></div> */}
                  <img
                    className="main-toggle__tag-img"
                    alt="world"
                    width="24px"
                    height="24px"
                    id="indiaTag"
                    style={{ display: "block" }}
                    src={world}
                  />
                  <p>All</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Mapsection
          status={this.state.country}
          update={this.state.lastUpdate}
          rawIndiaData={this.state.indiaRawData}
          whatsappText={this.state.whatsappText}
          twitterText={this.state.twitterText}
        />
        <LinechartDaily
          status={this.state.country}
          rawIndiaData={this.state.indiaRawDataDaily}
        />
        <Linechart
          status={this.state.country}
          rawIndiaData={this.state.indiaRawData}
          worldTimelineData={this.state.worldTimelineData}
        />

        {/* <BarChartIndia /> */}
        {/* <BarChartWorld /> */}
        <StateData
          indiaRawData={this.state.indiaRawData}
          districtData={this.state.districtData}
          stateDailyDelta={this.state.stateDailyDelta}
        />
        <CountryData
          indiaConfirmed={this.state.confirmedIndia}
          indiaRecovered={this.state.recoveredIndia}
          indiaDeaths={this.state.deathsIndia}
        />

        <Link to="/donate">
          <div className="button donate-for-mobile">How to Donate? </div>{" "}
        </Link>

        <p className="note">
          <sup> ✝ </sup> Realtime data from credible sources linked below.
          Numbers might take time to reflect on MOHFW website.
          <br />* Age data unavailable for some confirmed cases
        </p>
        {/* <StatePage stateName={null}/> */}
      </div>
    );
  }
}

export default Home;
