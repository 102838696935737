import React, { Component } from "react";
import { Link } from "react-router-dom";
import Location from "./Location";
//import HomepageEvents from './HomepageEvents.jsx';
import WhatsAppShare from "./WhatsAppShare";
import TwitterAppShare from "./TwitterAppShare";
import JobCard from "./jobCard";

let mapsection = (props) => {
  let IndiaCasesAPI = () => {
    // console.log(props.rawIndiaData);

    let info = props.rawIndiaData;

    if (info != undefined || info != null) {
      // console.log(info.statewise[0]);

      // console.log(info.cases_time_series[info.cases_time_series.length - 1]);
      // console.log(info)
      let active = info.statewise[0].active;
      let confirmed = info.statewise[0].confirmed;
      let recovered = info.statewise[0].recovered;
      let deaths = info.statewise[0].deaths;

      let yesterdayConfirmed =
        info.cases_time_series[info.cases_time_series.length - 1]
          .totalconfirmed;
      let dailyConfirmed = confirmed - yesterdayConfirmed;

      let yesterdayRecovered =
        info.cases_time_series[info.cases_time_series.length - 1]
          .totalrecovered;
      let dailyRecovered = recovered - yesterdayRecovered;

      let yesterdayDeaths =
        info.cases_time_series[info.cases_time_series.length - 1].totaldeceased;
      let dailyDeaths = deaths - yesterdayDeaths;

      // console.log(dailyDeaths);

      document.getElementById(
        "stat__confirmed"
      ).innerHTML = confirmed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      document.getElementById(
        "stat__recovered"
      ).innerHTML = recovered.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      document.getElementById(
        "stat__deaths"
      ).innerHTML = deaths.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      if (dailyConfirmed != 0) {
        document.getElementById("daily-confirmed").innerHTML =
          "[+" + dailyConfirmed + "]";
      } else {
        document.getElementById("daily-confirmed").innerHTML =
          "[+" +
          info.cases_time_series[info.cases_time_series.length - 1]
            .dailyconfirmed +
          "]";
      }

      if (dailyRecovered != 0) {
        document.getElementById("daily-recovered").innerHTML =
          "[+" + dailyRecovered + "]";
      } else {
        document.getElementById("daily-recovered").innerHTML =
          "[+" +
          info.cases_time_series[info.cases_time_series.length - 1]
            .dailyrecovered +
          "]";
      }

      if (dailyDeaths != 0) {
        document.getElementById("daily-deaths").innerHTML =
          "[+" + dailyDeaths + "]";
      } else {
        document.getElementById("daily-deaths").innerHTML =
          "[+" +
          info.cases_time_series[info.cases_time_series.length - 1]
            .dailydeceased +
          "]";
      }
      document.getElementById("stat__activecases").innerHTML =
        active.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      let num1 = (recovered / confirmed) * 100;
      document.getElementById("stat__recoveryrate").innerHTML =
        num1.toFixed(2) + "%";

      let num2 = (deaths / confirmed) * 100;
      document.getElementById("stat__deathrate").innerHTML =
        num2.toFixed(2) + "%";
    }
  };

  let jhuCasesAPI = () => {
    // console.log('maka invoke kela');

    fetch("https://corona.lmao.ninja/v2/all")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        document.getElementById(
          "stat__confirmed"
        ).innerHTML = data.cases
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        document.getElementById(
          "stat__recovered"
        ).innerHTML = data.recovered
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        document.getElementById(
          "stat__deaths"
        ).innerHTML = data.deaths
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        document.getElementById("daily-confirmed").innerHTML =
          "[+" +
          data.todayCases.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          "]";

        document.getElementById("daily-recovered").innerHTML = "[+" + "]";

        document.getElementById("daily-deaths").innerHTML =
          "[+" + data.todayDeaths + "]";

        document.getElementById("stat__activecases").innerHTML =
          data.active.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        let num1 = (data.recovered / data.cases) * 100;
        document.getElementById("stat__recoveryrate").innerHTML =
          num1.toFixed(2) + "%";

        let num2 = (data.deaths / data.cases) * 100;
        document.getElementById("stat__deathrate").innerHTML =
          num2.toFixed(2) + "%";
      })
      .catch((err) => {
        console.log("Kuch toh error hai");
      });
  };

  let runFirst = () => {
    if (props.status == "India") {
      IndiaCasesAPI();
    }

    if (props.status == "World") {
      jhuCasesAPI();
    }
  };

  let confirmedModal = () => {
    document.getElementsByClassName("confirmedModal")[0].style.display = "flex";
  };

  let closeconfirmedModal = () => {
    document.getElementsByClassName("confirmedModal")[0].style.display = "none";
  };

  return (
    <div>
      {runFirst()}
      {/* <span> {jhuCasesAPI()} </span> */}
      <div className="confirmedModal">
        <div className="modal">
          <button onClick={closeconfirmedModal}>&#10006;</button>
          <h3 className="modal__title">
            Why are the number of infected cases more than Mininstry of Health
            India?
          </h3>
          <p>
            MoHFW updates the data at a scheduled time. However, we update them
            based on verified APIs which collect data from state press
            bulletins, official handles and reliable news channels which could
            be more recent. The data is validated by group of volunteers from
            covid19india organisation. We have cited our sources in the footer
            of the website
          </p>

          <h3 className="modal__title">
            Is this an official/government website?
          </h3>
          <p>
            No, it is not. We are a group of student volunteers from Goa, India
            who want to prevent the spread of virus and help save lives.
          </p>
        </div>
      </div>

      <div className="map-stat-container">
        <div id="india-last-update">Last updated on {props.update}</div>

        <div className="stats-container">
          <div className="stat">
            <h4 className="stat__title">
              Total Confirmed{" "}
              <span className="info_tag" onClick={confirmedModal}>
                i
              </span>{" "}
            </h4>
            <div className="stat-inliner">
              <p
                id="stat__confirmed"
                className="stat__figure small"
                style={{ color: "#5851db" }}
              >
                {" "}
              </p>

              <span
                id="daily-confirmed"
                className="stat__daily-rise"
                style={{ color: "#5851db" }}
              ></span>
            </div>
          </div>

          <div className="stat">
            <h4 className="stat__title">Total Recovered</h4>
            <div className="stat-inliner">
              <p
                id="stat__recovered"
                className="stat__figure"
                style={{ color: "#4ccf76" }}
              >
                {" "}
              </p>
              <span
                id="daily-recovered"
                className="stat__daily-rise"
                style={{ color: "#4ccf76" }}
              ></span>
            </div>
          </div>
          <div className="stat">
            <h4 className="stat__title">Total Deaths</h4>
            <div className="stat-inliner">
              <p
                id="stat__deaths"
                className="stat__figure"
                style={{ color: "#e1306c" }}
              ></p>
              <span
                id="daily-deaths"
                className="stat__daily-rise"
                style={{ color: "#e1306c" }}
              ></span>
            </div>
          </div>

          <div className="stat">
            <div className="stat__block">
              <h4 className="stat__block__title">Active :</h4>
              <p
                id="stat__activecases"
                className="stat__block__figure"
                style={{ color: "#007bff" }}
              ></p>
            </div>

            <div className="stat__block">
              <h4 className="stat__block__title">Recovery rate :</h4>
              <p
                id="stat__recoveryrate"
                className="stat__block__figure"
                style={{ color: "#4ccf76" }}
              ></p>
            </div>

            <div className="stat__block">
              <h4 className="stat__block__title">Death rate :</h4>
              <p
                id="stat__deathrate"
                className="stat__block__figure"
                style={{ color: "#e1306c" }}
              ></p>
            </div>
          </div>
        </div>

        {/* <Location /> */}
        <div className="share-module-container">
          <WhatsAppShare whatsappText={props.whatsappText} />
          <TwitterAppShare twitterText={props.twitterText} />
        </div>

        {/* <div className="button-container">
                    <a href="https://bit.ly/ourTwitterAccount">
                        <div className="button twitter">
                            <svg viewBox="0 0 48 48" version="1.1">
                                <g id="surface4074571">
                                    <path d="M 48 8.601562 C 46.203125 9.398438 44.398438 10 42.398438 10.203125 C 44.398438 9 46 7 46.796875 4.796875 C 44.796875 6 42.796875 6.796875 40.601562 7.203125 C 38.601562 5.203125 36 4 33.203125 4 C 27.796875 4 23.398438 8.398438 23.398438 13.796875 C 23.398438 14.601562 23.398438 15.398438 23.601562 16 C 15.398438 15.601562 8.203125 11.796875 3.398438 5.796875 C 2.398438 7.203125 2 9 2 10.796875 C 2 14.203125 3.796875 17.203125 6.398438 19 C 4.796875 18.796875 3.203125 18.398438 2 17.796875 C 2 17.796875 2 17.796875 2 18 C 2 22.796875 5.398438 26.796875 9.796875 27.601562 C 9 27.796875 8.203125 28 7.203125 28 C 6.601562 28 6 28 5.398438 27.796875 C 6.601562 31.796875 10.203125 34.601562 14.601562 34.601562 C 11.203125 37.203125 7 38.796875 2.398438 38.796875 C 1.601562 38.796875 0.796875 38.796875 0 38.601562 C 4.398438 41.398438 9.601562 43 15 43 C 33.203125 43 43 28 43 15 C 43 14.601562 43 14.203125 43 13.796875 C 45 12.398438 46.601562 10.601562 48 8.601562 " />
                                </g>
                            </svg>
                            <p>Get live updates on COVID19 India</p>
                        </div>
                    </a>
                </div> */}

        {/* <Link to="/donate"><div className="button moved-up">Help fight COVID19</div></Link> */}
      </div>

      {/* <JobCard /> */}

      {/* <HomepageEvents /> */}

      {/* <div className="map">
        <div className="map__iframe-container" id="iframeContainer">
          <iframe
            title="heat pam"
            src="//www.arcgis.com/apps/Embed/index.html?webmap=41c5d95c84b745d9a10d9312ac71b2c0&extent=48.4204,16.8838,140.354,55.1257&home=true&zoom=true&previewImage=false&scale=true&search=true&searchextent=true&disable_scroll=true&theme=dark"
          ></iframe>
        </div>
      </div> */}
    </div>
  );
};

export default mapsection;
