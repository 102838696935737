import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';


class DeathChart extends Component {

    constructor(props) {
        super(props);

        // this.jhuCasesAPI();

    }

    state = {
        chartData: {
            // All dates should come down. Take weekly periods. DYNAMIC
            labels: ['22nd Jan', '1st Feb', '15th Feb', '1st March', 'today'],
            datasets: [
                {
                    label: 'Death',
                    data: [
                        17, 259, 1666, 2996,

                    ],
                    borderColor: '#e1306c',
                    backgroundColor: 'rgba(225, 48, 108, 0.1)'
                }
            ]
        },
    }

    // OLD METHOD TO GET DATA USING API CALL 

    // jhuCasesAPI = () => {

    //     // console.log('maka invoke kela');
    //     //function to convert date which is in form (mm/dd/yy)
    //     function getDate(a) {
    //         //let a = "3/26/2020";
    //         let trimmed = a.split("/");
    //         let dateNew = trimmed[1];
    //         //console.log(trimmed[1]);
    //         switch (trimmed[0]) {
    //             case "01":
    //             case "1":
    //                 dateNew += " January";
    //                 break;
    //             case "02":
    //             case "2":
    //                 dateNew += " February";
    //                 break;
    //             case "03":
    //             case "3":
    //                 dateNew += " March";
    //                 break;
    //             case "04":
    //             case "4":
    //                 dateNew += " April";
    //                 break;
    //             case "05":
    //             case "5":
    //                 dateNew += " May";
    //                 break;
    //             case "06":
    //             case "6":
    //                 dateNew += " June";
    //                 break;
    //             case "07":
    //             case "7":
    //                 dateNew += " July";
    //                 break;
    //             case "08":
    //             case "8":
    //                 dateNew += " August";
    //                 break;
    //             case "09":
    //             case "9":
    //                 dateNew += " September";
    //                 break;
    //             case "10":
    //                 dateNew += " October";
    //                 break;
    //             case "11":
    //                 dateNew += " November";
    //                 break;
    //             case "12":
    //                 dateNew += " December";
    //                 break;
    //             default:
    //                 break;
    //         }
    //         //console.log(dateNew);
    //         return dateNew;
    //     }

    //     fetch('https://corona.lmao.ninja/v2/historical')
    //         .then(response => {
    //             return response.json();
    //         })
    //         .then(res => {
    //             let counter = 0; //counter for counting days iterated through
    //             let days = 0; //number of days
    //             let labels = Object.keys(res[0].timeline.deaths); //actual date labels given by the API
    //             let updatedLabels = []; //API gives dates like (1/26/2020), this array will have labels like (26 January)
    //             let dataPoints = []; //these are the datapoints to plot


    //             //let pointsPlotted = 0;

    //             days = labels.length; //total number of days given by the API 
    //             let offset = days - 30; //Used to mark the start of the 30 datapoints to plot
    //             //offset = Math.round((days - 32)/(10)); //offset claculated here
    //             let daysTrimmed = 30; //30 days for which data is to be plotted

    //             // let midLabel = Math.round(daysTrimmed/2);
    //             // let midLabelLow = Math.round(midLabel / 2);
    //             // let midLabelHigh = Math.round((midLabel + daysTrimmed) / 2);

    //             //taking 2 midpoints instead of 3 now
    //             let midLabelLow = Math.round(daysTrimmed * (1 / 3));
    //             let midLabelHigh = Math.round(daysTrimmed * (2 / 3));
    //             let pointsPlotted = 0;

    //             labels.map(item => {
    //                 if (counter >= offset) {
    //                     if ((counter === offset) || (counter === days - 1) || (pointsPlotted === midLabelHigh) || (pointsPlotted === midLabelLow)) {
    //                         updatedLabels.push(getDate(item));
    //                     }
    //                     else {
    //                         updatedLabels.push('');
    //                     }
    //                     var total = 0;
    //                     res.map(data => {
    //                         total += data.timeline.deaths[item];
    //                     });
    //                     dataPoints.push(total);
    //                     pointsPlotted += 1;
    //                 }
    //                 counter += 1;
    //             });

    //             // console.log(updatedLabels);
    //             // console.log(dataPoints);
    //             /*res.map(item => {
    //                 console.log(item.timeline.cases["1/22/20"]);
    //             });*/

    //             this.setState({
    //                 chartData: {
    //                     // All dates should come down. Take weekly periods. DYNAMIC
    //                     labels: updatedLabels,
    //                     datasets: [
    //                         {
    //                             label: 'Death',
    //                             data: dataPoints,
    //                             borderColor: '#e1306c',
    //                             backgroundColor: 'rgba(225, 48, 108, 0.1)'
    //                         }
    //                     ]
    //                 },
    //             });

    //         })
    //         .catch(err => console.log(err));

    // }

    // NEW METHOD TO GET DATA USING PROPS
    static getDerivedStateFromProps(nextProps, prevState) {
        let res = nextProps.worldTimelineData;

        if (res != null || res != undefined) {
            // console.log(res);

            function getDate(a) {
                //let a = "3/26/2020";
                let trimmed = a.split("/");
                let dateNew = trimmed[1];
                //console.log(trimmed[1]);
                switch (trimmed[0]) {
                    case "01":
                    case "1":
                        dateNew += " January";
                        break;
                    case "02":
                    case "2":
                        dateNew += " February";
                        break;
                    case "03":
                    case "3":
                        dateNew += " March";
                        break;
                    case "04":
                    case "4":
                        dateNew += " April";
                        break;
                    case "05":
                    case "5":
                        dateNew += " May";
                        break;
                    case "06":
                    case "6":
                        dateNew += " June";
                        break;
                    case "07":
                    case "7":
                        dateNew += " July";
                        break;
                    case "08":
                    case "8":
                        dateNew += " August";
                        break;
                    case "09":
                    case "9":
                        dateNew += " September";
                        break;
                    case "10":
                        dateNew += " October";
                        break;
                    case "11":
                        dateNew += " November";
                        break;
                    case "12":
                        dateNew += " December";
                        break;
                    default:
                        break;
                }
                //console.log(dateNew);
                return dateNew;
            }


            let counter = 0; //counter for counting days iterated through
            let days = 0; //number of days
            let labels = Object.keys(res[0].timeline.deaths); //actual date labels given by the API
            let updatedLabels = []; //API gives dates like (1/26/2020), this array will have labels like (26 January)
            let dataPoints = []; //these are the datapoints to plot


            //let pointsPlotted = 0;

            days = labels.length; //total number of days given by the API 
            let offset = days - 30; //Used to mark the start of the 30 datapoints to plot
            //offset = Math.round((days - 32)/(10)); //offset claculated here
            let daysTrimmed = 30; //30 days for which data is to be plotted

            // let midLabel = Math.round(daysTrimmed/2);
            // let midLabelLow = Math.round(midLabel / 2);
            // let midLabelHigh = Math.round((midLabel + daysTrimmed) / 2);

            //taking 2 midpoints instead of 3 now
            let midLabelLow = Math.round(daysTrimmed * (1 / 3));
            let midLabelHigh = Math.round(daysTrimmed * (2 / 3));
            let pointsPlotted = 0;

            labels.map(item => {
                if (counter >= offset) {
                    if ((counter === offset) || (counter === days - 1) || (pointsPlotted === midLabelHigh) || (pointsPlotted === midLabelLow)) {
                        updatedLabels.push(getDate(item));
                    }
                    else {
                        updatedLabels.push('');
                    }
                    var total = 0;
                    res.map(data => {
                        total += data.timeline.deaths[item];
                    });
                    dataPoints.push(total);
                    pointsPlotted += 1;
                }
                counter += 1;
            });

            // console.log(updatedLabels);
            // console.log(dataPoints);
            /*res.map(item => {
                console.log(item.timeline.cases["1/22/20"]);
            });*/

            //THIS IS THE this.setState() equivalent
            return({
                chartData: {
                    // All dates should come down. Take weekly periods. DYNAMIC
                    labels: updatedLabels,
                    datasets: [
                        {
                            label: 'Death',
                            data: dataPoints,
                            borderColor: '#e1306c',
                            backgroundColor: 'rgba(225, 48, 108, 0.1)'
                        }
                    ]
                },
            });

        }
        else{
            return null;
        }

    }


    render() {
        return (
            <div className="chart__graph-container death-chart">
                <Line
                    data={this.state.chartData}
                    options={{
                        scales: {
                            xAxes: [{
                                ticks: {
                                    autoSkip: false,
                                    maxRotation: 0,
                                    minRotation: 0
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                    stepSize: 20000,
                                }
                            }]
                        },
                        responsive: true,
                        maintainAspectRatio: false
                    }}
                />
            </div>
        )
    }
}

export default DeathChart;