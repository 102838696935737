import React, { Component } from "react";
import { Redirect } from "react-router-dom";
//import ScrollToTop from "../scrollToTop.jsx";
import buttonVisibility from '../buttonVisibility';
import zenscroll from "zenscroll";
import StateDailyCharts from './StateDailyCharts';
import StateCumCharts from './StateCumCharts';

class StatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateName: "none",
      stateDistricts: {},
      confirmed: 0,
      active: 0,
      recovered: 0,
      dead: 0,
      confirmedDaily: 0,
      activeDaily: 0,
      recoveredDaily: 0,
      deadDaily: 0,
      sortCounter: 1,
      sortTypeArr: [
        "Alphabetically",
        "Highest Confirmed",
        "Highest Active",
        "Highest Recovered",
        "Highest Deaths",
      ],
    };
  }

  showStateData = () => {
    // document.getElementsByClassName("state-page")[0].style.zIndex = "4";
    // document.getElementsByClassName("state-page")[0].style.display = "block";
    // document.getElementsByClassName("state-page")[0].style.overflowY = "scroll";
  };

  //   componentDidMount(){
  //     window.addEventListener('popstate', this.buttonVisibility);
  //   }


  addToTableSort = (elements) => {
    let tab = document.getElementById("district-table-body");

    while (tab.firstChild) {
      tab.removeChild(tab.firstChild);
    }

    let rank = 1;
    console.log(elements, this.state.sortCounter);
    elements.forEach((element, index) => {
      //console.log(element.district, index);
      let tr = document.createElement("tr");
      //let td0 = document.createElement('td');
      let td1 = document.createElement("td");
      let td2 = document.createElement("td");
      let td3 = document.createElement("td");
      let td4 = document.createElement("td");
      let td5 = document.createElement("td");

      //td0.innerHTML = rank.toString();
      td1.innerHTML = element["district"];
      td2.innerHTML = element["confirmed"]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      td3.innerHTML =
        element["active"] < 0
          ? 0
          : element["active"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      td4.innerHTML = element["recovered"]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      td5.innerHTML = element["dead"]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      //tr.appendChild(td0);
      tr.appendChild(td1);
      tr.appendChild(td2);
      tr.appendChild(td3);
      tr.appendChild(td4);
      tr.appendChild(td5);

      tab.appendChild(tr);
      rank += 1;
    });
  };

  addToTable = (elements) => {
    let tab = document.getElementById("district-table-body");

    while (tab.firstChild) {
      tab.removeChild(tab.firstChild);
    }

    let rank = 1;
    for (let element in elements) {
      let tr = document.createElement("tr");
      //let td0 = document.createElement('td');
      let td1 = document.createElement("td");
      let td2 = document.createElement("td");
      let td3 = document.createElement("td");
      let td4 = document.createElement("td");
      let td5 = document.createElement("td");

      //td0.innerHTML = rank.toString();
      td1.innerHTML = element;
      td2.innerHTML = elements[element]["confirmed"]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      td3.innerHTML =
        elements[element]["active"] < 0
          ? 0
          : elements[element]["active"]
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      td4.innerHTML = elements[element]["recovered"]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      td5.innerHTML = elements[element]["deceased"]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      //tr.appendChild(td0);
      tr.appendChild(td1);
      tr.appendChild(td2);
      tr.appendChild(td3);
      tr.appendChild(td4);
      tr.appendChild(td5);

      tab.appendChild(tr);
      rank += 1;
    }
  };

  componentDidMount() {

    window.addEventListener('scroll', buttonVisibility);

    if (
      (this.props.location.data)
    ) {
      let stateName = this.props.location.data.stateName;
      let stateDistricts = this.props.location.data.currentState;
      let totalConfirmed = 0,
        totalActive = 0,
        totalRecovered = 0,
        totalDead = 0;

      this.addToTable(stateDistricts);

      for (let key in stateDistricts) {
        //console.log(stateDistricts[key]['confirmed']);
        totalConfirmed += stateDistricts[key]["confirmed"];
        totalRecovered += stateDistricts[key]["recovered"];
        totalDead += stateDistricts[key]["deceased"];
        totalActive += stateDistricts[key]["active"];
      }

      this.showStateData();
      this.setState({
        stateName: stateName,
        confirmed: totalConfirmed,
        active: totalActive,
        recovered: totalRecovered,
        dead: totalDead,
        stateDistricts: stateDistricts,
        confirmedDaily:
          this.props.location.data.stateDelta.deltaconfirmed !== "0"
            ? "[+" + this.props.location.data.stateDelta.deltaconfirmed + "]"
            : "",
        activeDaily: "",
        recoveredDaily:
          this.props.location.data.stateDelta.deltarecovered !== "0"
            ? "[+" + this.props.location.data.stateDelta.deltarecovered + "]"
            : "",
        deadDaily:
          this.props.location.data.stateDelta.deltadeaths !== "0"
            ? "[+" + this.props.location.data.stateDelta.deltadeaths + "]"
            : "",
        sortCounter: 1,
      });

      zenscroll.toY(0);
    }
  }

  componentWillMount() {
    window.removeEventListener('scroll', buttonVisibility);
  }

  sortClicked = () => {
    let districtData = this.state.stateDistricts;
    let arr = [];

    for (let key in districtData) {
      let obj = {};
      obj["confirmed"] = districtData[key]["confirmed"];
      obj["recovered"] = districtData[key]["recovered"];
      obj["dead"] = districtData[key]["deceased"];
      obj["active"] = districtData[key]["active"];
      obj["district"] = key;
      arr.push(obj);
    }

    if (this.state.sortCounter === 0) {
      this.setState({
        sortCounter:
          (this.state.sortCounter + 1) % this.state.sortTypeArr.length,
      });
      this.addToTable(districtData);
    } else {
      if (this.state.sortCounter === 1) {
        arr.sort(function (a, b) {
          return b.confirmed - a.confirmed;
        });
      }
      if (this.state.sortCounter === 2) {
        arr.sort(function (a, b) {
          return b.active - a.active;
        });
      }
      if (this.state.sortCounter === 3) {
        arr.sort(function (a, b) {
          return b.recovered - a.recovered;
        });
      }
      if (this.state.sortCounter === 4) {
        arr.sort(function (a, b) {
          return b.dead - a.dead;
        });
      }
      this.setState({
        sortCounter:
          (this.state.sortCounter + 1) % this.state.sortTypeArr.length,
      });
      this.addToTableSort(arr);
    }
  };

  hideStateData = () => {
    //this.props.resetState();
    document.getElementsByClassName("state-page")[0].style.zIndex = "-1";
    document.getElementsByClassName("state-page")[0].style.display = "none";
    document.getElementsByClassName("state-page")[0].style.overflowY = "hidden";
    this.props.resetState();
  };

  render() {
    if (!this.props.location.data) {
      return <Redirect to="/" />;
    }

    return (
      <div className="state-page" id="state-wise-data">
        <h2 className="state__title big">{this.state.stateName}</h2>
        <div className="map-stat-container">
          <div className="stats-container">
            <div className="stat">
              <h4 className="stat__title">Confirmed</h4>
              <div className="stat-inliner">
                <p
                  className="stat__figure small"
                  style={{ color: "rgb(88,81,219)" }}
                >
                  {this.state.confirmed
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
                <span
                  className="stat__daily-rise"
                  style={{ color: "rgb(88,81,219)" }}
                >
                  {this.state.confirmedDaily}
                </span>
              </div>
            </div>

            <div className="stat">
              <h4 className="stat__title">Active</h4>
              <div className="stat-inliner">
                <p className="stat__figure small" style={{ color: "#007bff" }}>
                  {this.state.active
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
                <span className="stat__daily-rise" style={{ color: "#007bff" }}>
                  {this.state.activeDaily}
                </span>
              </div>
            </div>

            <div className="stat">
              <h4 className="stat__title">Recovered</h4>
              <div className="stat-inliner">
                <p className="stat__figure small" style={{ color: "#4ccf76" }}>
                  {this.state.recovered
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
                <span className="stat__daily-rise" style={{ color: "#4ccf76" }}>
                  {this.state.recoveredDaily}
                </span>
              </div>
            </div>

            <div className="stat">
              <h4 className="stat__title">Deaths</h4>
              <div className="stat-inliner">
                <p className="stat__figure small" style={{ color: "#e1306c" }}>
                  {this.state.dead
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
                <span className="stat__daily-rise" style={{ color: "#e1306c" }}>
                  {this.state.deadDaily}
                </span>
              </div>
            </div>
          </div>
        </div>

        <StateDailyCharts stateName={this.state.stateName} />
        <StateCumCharts stateName={this.state.stateName} />

        <div className="state state-wise-data district">
          <h3 className="state__title">District List</h3>
          <div className="state__sort-button" onClick={this.sortClicked}>
            Sorted:{" "}
            {
              this.state.sortTypeArr[
              this.state.sortCounter === 0 ? 4 : this.state.sortCounter - 1
              ]
            }
          </div>
          <div className="state__table-container">
            <table>
              <thead>
                <tr>
                  <th>Dist</th>
                  <th>Cnfrm</th>
                  <th>Actve</th>
                  <th>Rcvrd</th>
                  <th>Dead</th>
                </tr>
              </thead>

              <tbody id="district-table-body"></tbody>
            </table>
          </div>
        </div>
        {/* <ScrollToTop /> */}
      </div>
    );
  }
}

export default StatePage;
