import React, { Component } from "react";
import zenscroll from "zenscroll";

class JobForm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    zenscroll.toY(0);
  }

  render() {
    return (
      <div>
        <iframe
          style={{ width: "100%", minHeight: "1000px" }}
          src="https://docs.google.com/forms/d/e/1FAIpQLSd37VkLX6WISRO-BUML9n-uxtz3NXewapFx5l4eWJ2BVhrfWQ/viewform?embedded=true"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
    );
  }
}

export default JobForm;
