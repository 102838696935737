import React, { Component } from "react";
import { Link } from "react-router-dom";

class JobCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="events-container" style={{ marginTop: "25px" }}>
          <div className="eventCard">
            <h2 className="eventCard__title">Seeking a job? We can help you</h2>
            <p className="eventCard__text">
              👨‍💻 We have collaborated with <b>COVID-19 Free Jobs Forum</b>, who
              are helping people get jobs and also helping out people who want
              to hire. Just enter some details in our form and we will get back
              to you.
            </p>
            <br />
            <a href="https://bit.ly/CoronaTrackerJobs" target="blank">
              <p className="button jobApply">
                <span className="job-button-text">Apply Here</span>
                {/* <Link to="/jobs">
                  <span className="job-button-text">Apply Here</span>
                </Link> */}
              </p>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default JobCard;
